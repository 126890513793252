<template>
  <div class="company-stats">
    <brand-stats-revenue-modal
      :active="revenueModalActive"
      :info="revenueSelected"
      @close="closeEventRevenueModal"
    />
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <div class="content-path">
          <breadcrumbs :crumbs="breadcrumbs" />
        </div>
        <product-stat-notice />
        <div class="section-wrapper">
          <h3>Brand Information</h3>
          <section class="brand-information">
            <div class="login-info">
              <div class="profile company-profile">
                <div class="profile-wrapper">
                  <img
                    class="profile-image"
                    :src="company.profile_img"
                    alt="Customer Profile Image"
                  />
                </div>
              </div>
              <div class="login-name">
                <p class="title">{{ company.name }}</p>
                <p class="date" title="Last Login">
                  <font-awesome-icon icon="sign-in-alt" />
                  {{ company.last_login }}
                </p>
                <p title="Email Address">
                  <font-awesome-icon icon="envelope" />
                  {{ company.email }}
                </p>
                <p title="Phone Number">
                  <font-awesome-icon icon="phone" />
                  {{ company.phone }}
                </p>
                <p>
                  <router-link to="/brand-settings">
                    Edit Brand Settings
                  </router-link>
                </p>
              </div>
            </div>
            <div class="totals">
              <div class="border-wrapper">
                <div class="content-container">
                  <h4>Total Events Listed</h4>
                  <p>{{ company.listed }}</p>
                </div>
                <div class="content-container">
                  <h4>Total Revenue Collected</h4>
                  <p>
                    {{ currentStoreCurrency.name }}
                    {{
                      (
                        company.total_revenue * currentStoreCurrency.value
                      ).toFixed(2)
                    }}
                  </p>
                </div>
                <div class="content-container">
                  <h4>Total Tickets Released</h4>
                  <p>{{ company.tickets_released }}</p>
                </div>
                <div class="content-container">
                  <h4>Average Ticket Price</h4>
                  <p>
                    {{ currentStoreCurrency.name }}
                    {{
                      (
                        company.average_ticket_price *
                        currentStoreCurrency.value
                      ).toFixed(2)
                    }}
                  </p>
                </div>
              </div>
            </div>
          </section>
          <div class="section-wrapper">
            <h3>Best Customers</h3>
            <section class="best-customers">
              <div class="table tickets-purchased">
                <h4>Tickets Purchased</h4>
                <div class="table-headers">
                  <span>Customer</span>
                  <span>Tickets Purchased</span>
                </div>
                <div
                  v-for="(customer, index) in bestCustomers.ticketsPurchased"
                  class="table-value"
                  :key="index"
                >
                  <span class="number">{{ index + 1 }}.</span>
                  <span class="name">
                    <span>{{ customer.name }}</span>
                    <small>{{ customer.email }}</small>
                  </span>
                  <span>{{ customer.purchased }}</span>
                </div>
                <div
                  v-if="!bestCustomers.ticketsPurchased.length"
                  class="table-value"
                >
                  <span>N/A</span>
                </div>
              </div>
              <div class="table money-spent">
                <h4>Money Spent</h4>
                <div class="table-headers">
                  <span>Customer</span>
                  <span>Money Spent</span>
                </div>
                <div
                  v-for="(customer, index) in bestCustomers.moneySpent"
                  class="table-value"
                  :key="index"
                >
                  <span class="number">{{ index + 1 }}.</span>
                  <span class="name">
                    <span>{{ customer.name }}</span>
                    <small>{{ customer.email }}</small>
                  </span>
                  <span>{{ customer.spent }}</span>
                </div>
                <div
                  v-if="!bestCustomers.moneySpent.length"
                  class="table-value"
                >
                  <span>N/A</span>
                </div>
              </div>
              <div class="table scan-ins">
                <h4>Tickets Scanned In</h4>
                <div class="table-headers">
                  <span>Customer</span>
                  <span>Ticket Scan Ins</span>
                </div>
                <div
                  v-for="(customer, index) in bestCustomers.scanIns"
                  class="table-value"
                  :key="index"
                >
                  <span class="number">{{ index + 1 }}.</span>
                  <span class="name">
                    <span>{{ customer.name }}</span>
                    <small>{{ customer.email }}</small>
                  </span>
                  <span>{{ customer.scanIns }}</span>
                </div>
                <div v-if="!bestCustomers.scanIns.length" class="table-value">
                  <span>N/A</span>
                </div>
              </div>
            </section>
          </div>
          <div class="section-container left">
            <h3>Most Profitable Events</h3>
            <section class="profitable-events">
              <div class="table tickets-purchased">
                <div class="table-headers">
                  <span>Event</span>
                  <span>Revenue</span>
                </div>
                <div
                  v-for="(event, index) in mostProfitableEvents"
                  class="table-value"
                  :key="index"
                >
                  <span class="number">{{ index + 1 }}.</span>
                  <span class="name">
                    <span>{{ event.name }}</span>
                    <small>{{ event.date }}</small>
                  </span>
                  <span>${{ event.revenue }}</span>
                </div>
                <div v-if="!mostProfitableEvents.length" class="table-value">
                  <span>N/A</span>
                </div>
              </div>
            </section>
          </div>
          <div class="section-container right">
            <h3>Most Attended Events</h3>
            <section class="attended-events">
              <div class="table tickets-purchased">
                <div class="table-headers">
                  <span>Event</span>
                  <span>Attendees</span>
                </div>
                <div
                  v-for="(event, index) in mostAttendedEvents"
                  class="table-value"
                  :key="index"
                >
                  <span class="number">{{ index + 1 }}.</span>
                  <span class="name">
                    <span>{{ event.name }}</span>
                    <small>{{ event.date }}</small>
                  </span>
                  <span>{{ event.attendees }}</span>
                </div>
                <div v-if="!mostAttendedEvents.length" class="table-value">
                  <span>N/A</span>
                </div>
              </div>
            </section>
          </div>
          <div class="crm-section-container">
            <h3>Instant Email</h3>
            <section class="instant-email">
              <div class="item">
                <h4>Total Buyers</h4>
                <p>{{ crm.totalBuyers }}</p>
              </div>
              <div class="item">
                <h4>Emails Sent</h4>
                <p>{{ crm.emailsSent }}</p>
              </div>
              <div class="item">
                <h4>Total Duplicate Emails</h4>
                <p>{{ crm.duplicateEmails }}</p>
              </div>
            </section>
          </div>
          <section>
            <div class="row">
              <div class="col full">
                <table-tote
                  v-model="revenueTableData"
                  @modal="openEventRevenueModal"
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.company-stats {
  .content-inner {
    section {
      display: flex;
      justify-content: space-between;
      background: var(--dashboard-tiles);
      border: 1px solid var(--dashboard-border);
      box-shadow: var(--dashboard-shadow);

      // border-top: 1px solid @colors[divider];

      .title {
        .b1;
        color: @colors[primary-green];
        font-weight: bold;
      }

      .side-container {
        margin-top: 10px;

        p {
          .b2;
        }
      }
    }

    .crm {
      p {
        .b2;
      }
    }

    .section-container {
      width: calc(50% - 10px);
      display: inline-block;
      vertical-align: top;

      &.left {
        margin-right: 10px;
      }

      &.right {
        margin-left: 10px;
      }

      @media screen and (max-width: 850px) {
        width: 100%;
        margin: 0 !important;
      }
    }

    .brand-information {
      padding: 10px 15px;

      border-radius: 7px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .login-info {
        display: flex;

        .login-name {
          margin: auto 20px;

          .title {
            margin-bottom: 20px;
          }

          p {
            display: flex;
            margin: 10px 0;
            align-items: center;

            .fa-sign-in-alt,
            .fa-envelope,
            .fa-phone {
              margin-right: 10px;
              font-size: 15px;
            }
          }
        }
      }

      .totals {
        max-width: 50%;

        .border-wrapper {
          display: flex;
          flex-wrap: wrap;

          .content-container {
            width: 50%;
            text-align: center;
            margin: 10px 0;

            h4 {
              margin: 0;
            }

            p {
              margin: 0;
              font-weight: bold;
              font-size: 16px;
            }
          }
        }
      }

      @media screen and (max-width: 1080px) {
        flex-direction: column;

        .totals {
          max-width: 100%;
          margin-top: 15px;
        }
      }
    }

    .best-customers {
      padding: 10px;

      border-radius: 7px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .table {
        margin: 15px 0 15px 15px;
        padding-right: 15px;
        border-right: 1px solid #333b3f;

        &:last-of-type {
          border-right: none;
        }
      }

      @media screen and (max-width: 1080px) {
        flex-direction: column;

        .table {
          width: calc(100% - 30px);
          border-right: none;
        }
      }
    }

    .profitable-events {
      padding: 10px 15px;

      border-radius: 7px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .attended-events {
      padding: 10px 15px;

      border-radius: 7px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .instant-email {
      padding: 10px 15px;

      border-radius: 7px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;

      .item {
        width: 33%;
        text-align: center;
        margin: 10px 0;

        h4 {
          margin: 0;
        }

        p {
          margin: 0;
          font-weight: bold;
          font-size: 16px;
        }

        @media screen and (max-width: 800px) {
          width: 50%;

          &:last-of-type {
            width: 100%;
          }
        }
      }
    }

    .profile {
      width: 130px;
      height: 130px;
      margin: auto;
      border-radius: 100%;
      background-image: @colors[primary-gradient];
      object-fit: cover;
      object-position: center;

      &.cust-profile {
        width: 80px;
        height: 80px;

        .profile-wrapper {
          width: 70px;
          height: 70px;
        }
      }

      .profile-wrapper {
        width: 120px;
        height: 120px;
        background-color: @colors[profile-img-bg];
        border-radius: 100%;
        position: relative;
        left: 5px;
        top: 5px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        .profile-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .table {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin: 10px;

      h4 {
        margin: 0 0 10px 0;
        text-align: center;
      }

      .table-headers {
        display: flex;
        justify-content: space-between;
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid #333b3f;
      }

      .table-value {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;

        .number {
          width: 40px;
          font-size: 20px;
        }

        .name {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
      }
    }
  }
}
</style>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import dateFormat from "dateformat";
import formatMoney from "@/helpers/money";
import TableTote from "@/components/TableTote.vue";
import BrandStatsRevenueModal from "../components/modals/BrandStatsRevenueModal.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPhone,
  faEnvelope,
  faSignInAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ProductStatNotice from "../components/ProductStatNotice.vue";

library.add(faPhone, faEnvelope, faSignInAlt);

export default {
  name: "company-stats",
  components: {
    Breadcrumbs,
    TableTote,
    BrandStatsRevenueModal,
    FontAwesomeIcon,
    ProductStatNotice
  },
  head() {
    return {
      title: "Brand Stats"
    };
  },
  data() {
    return {
      breadcrumbs: [
        ["Dashboard", "/dashboard"],
        ["Brand Stats", null]
      ],
      company: {},
      bestCustomers: {
        ticketsPurchased: [],
        moneySpent: [],
        scanIns: []
      },
      mostProfitableEvents: [],
      mostAttendedEvents: [],
      crm: {},
      revenueTableData: {},
      payoutsTableData: {},
      revenueModalActive: false,
      revenueSelected: {}
    };
  },
  computed: {
    currentStoreCurrency() {
      return this.$store.state.currency;
    }
  },
  methods: {
    openEventRevenueModal(revenue) {
      this.revenueModalActive = true;
      this.revenueSelected = revenue;
    },
    closeEventRevenueModal() {
      this.revenueModalActive = false;
    }
  },
  created() {
    this.$store.commit("setTitle", "Brand Stats");
    this.$loader.start();

    this.$axios
      .post("/company/stats", { url: this.subdomain })
      .then(response => {
        const data = response.data;

        this.company = data.company;
        this.company.profile_img = data.company.profile_img
          ? process.env.VUE_APP_IMAGE_URL + this.company.profile_img
          : "/img/placeholders/avatar.png";
        this.company.last_login = dateFormat(
          +this.company.last_login.$date.$numberLong,
          "ddd, mmm dS, yyyy"
        );
        this.company.total_revenue = formatMoney(+this.company.total_revenue);

        data.bestCustomers.moneySpent.forEach(c => {
          c.spent = formatMoney(+c.spent);
        });

        this.bestCustomers = data.bestCustomers;

        this.mostProfitableEvents = data.mostProfitableEvents.map(e => {
          e.date = dateFormat(+e.date, "ddd, mmm dS, yyyy");
          e.revenue = formatMoney(+e.revenue);

          return e;
        });

        this.mostAttendedEvents = data.mostAttendedEvents.map(e => {
          e.date = dateFormat(+e.date, "ddd, mmm dS, yyyy");

          return e;
        });

        this.crm = data.crm;

        this.revenueTableData = {
          title: "Lifetime Ticket Revenue",
          columns: [
            {
              title: "Date (UTC)",
              field: "date_display",
              width: "20%",
              date: true
            },
            {
              title: "Amount",
              field: "amount",
              width: "15%",
              isCurrency: true
            },
            { title: "Events", field: "events_display", width: "57%" },
            {
              title: "View All",
              field: "icon",
              width: "8%",
              modal: true,
              modalTitle: "View"
            }
          ],
          data: data.lifetimeRevenue
            .sort((a, b) => b.date.localeCompare(a.date))
            .map(r => {
              r.date_display = dateFormat(r.date, "UTC:ddd, mmm dS, yyyy");
              r.events_display = r.events_text = Object.values(r.events)
                .map(e => e.name)
                .join(", ");

              if (r.events_display.length > 80) {
                r.events_display = r.events_display.slice(0, 80) + "...";
              }

              return r;
            }),
          searchable: ["events_text"],
          callbacks: {},
          totals: {
            amount: data =>
              data
                .map(r => +r.amount)
                .reduce((a, b) => a + b, 0)
                .toFixed(2)
          }
        };

        console.log(response.data);
      })
      .finally(() => {
        this.$loader.stop();
      });
  }
};
</script>
