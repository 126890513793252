<template>
  <div class="product-stat-notice">
    <div>
      At this time Product Financials are exclusively available on the Event
      Financials page which can be accessed from the
      <router-link to="/dashboard">Dashboard</router-link>
      .
    </div>
  </div>
</template>

<script>
export default {
  name: "product-stat-notice"
};
</script>

<style lang="less" scoped>
.product-stat-notice {
  padding: 20px;
  border: 1px solid var(--info-text-border);
  border-radius: 8px;
}
</style>