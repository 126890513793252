<template>
  <modal title="Revenue Breakdown" :active="active" @close="$emit('close')">
    <h4 style="margin-top: 0">Revenue for {{ info.date }}</h4>
    <div v-for="event in events" :key="event.name">
      <span class="event">{{ event.name }}</span>
      <span class="amount"> ${{ event.amount }} </span>
    </div>

    <template v-slot:footer>
      <diyobo-button type="secondary" txt="Close" v-on:click="$emit('close')" />
    </template>
  </modal>
</template>

<script>
import DiyoboButton from "@/components/DiyoboButton.vue";
import Modal from "@/components/Modal.vue";

export default {
  name: "brand-stats-revenue-modal",
  components: {
    DiyoboButton,
    Modal,
  },
  props: {
    active: Boolean,
    info: Object,
  },
  computed: {
    events() {
      if (!this.info.events) {
        return [];
      }

      return Object.values(this.info.events);
    },
  },
};
</script>

<style lang="less" scoped>
.modal {
  .modal-inner {
    .modal-dialog {
      .modal-body {
        .amount {
          font-weight: bold;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
