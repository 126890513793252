const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
});

export default function formatMoney(value, dollarSign) {
  let formatted = formatter.format(value);
  if (!dollarSign) return formatted;
  return formatted.replace('$', '');
}
